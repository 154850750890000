<script lang="ts" setup>
const $api = useApi();
const { setValidation, required } = useValidation();
const modalStore = useModalStore();

const props = defineProps({
  foodBrand: {
    type: Object,
    default: () => {},
  },
  onConfirm: Function,
  create: Boolean,
});

const foodBrandData = reactive({
  label: props.foodBrand?.label,
  description: props.foodBrand?.description,
});

const validation = setValidation(foodBrandData, {
  label: { required },
  description: { required },
});

async function handleCreateFoodBrand() {
  await $api.post(`/food/brand`, foodBrandData);
  modalStore.closeModal();
  props.onConfirm();
}

async function handleUpdateFoodBrand() {
  await $api.put(`/food/brand/${props.foodBrand.id}`, foodBrandData);
  modalStore.closeModal();
  props.onConfirm();
}

async function handleDeleteBrand() {
  try {
    await $api.delete(`/food/brand/${props.foodBrand.id}`);
    modalStore.closeModal();
    props.onConfirm();
  } catch (e) {}
}
</script>
<template>
  <BaseModal title="Editer une marque">
    <form>
      <BaseInput
        v-model="validation.label.$model"
        label="Label"
        type="text"
        :validation="validation.label"
        required
      />

      <BaseInput
        v-model="validation.description.$model"
        label="Description"
        type="text"
        :validation="validation.description"
      />
    </form>

    <template #footer>
      <BackofficeDeleteItem
        v-if="props.foodBrand"
        :text-popover="`Supprimer la marque ${props.foodBrand.label} ?`"
        @on-confirm="handleDeleteBrand"
      />
      <BaseButton
        text="Annuler"
        type="secondary"
        @click="modalStore.closeModal()"
      />

      <BaseButton
        text="Confirmer"
        @click="
          props.create ? handleCreateFoodBrand() : handleUpdateFoodBrand()
        "
      />
    </template>
  </BaseModal>
</template>
